<template>
<!--  모바일 -->
  <template v-if="isMobile">
    <div class="popup-container p-0 d-block" id="kb-mobile-popup" :class="{ 'is-active' : show }">
      <HrdCloudMobileHeader title="HRD 로드맵 담기" :show-back="true" :go-back-func="closePopup" />
      <main class="kb-main">
        <div class="main-content main-component">
          <article class="content-section mt-0">
            <header class="section-header header-divider">
              <h3 class="title text-gold">[{{roadmap.hrdCloudCateNm}} {{roadmap.hrdCloudCateLevelNm}}] {{roadmap.roadmapTitle}}</h3>
            </header>
            <div class="section-contents">
              <p v-if="items.length === 0" class="text">담기 가능한 콘텐츠가 없습니다.</p>
              <p v-for="(item,idx) in items" :key="idx" class="text py-1">{{idx+1}}. {{item.objNm}}</p>
              <p class="text-muted pt-3">
                ※ HRD 클라우드 로드맵 – 그룹러닝 콘텐츠 담기는 동영상만 가능합니다.(전산실습, PDF 제외)
              </p>
            </div>
          </article>
          <div class="page-buttons mt-0">
            <button class="kb-btn kb-btn-primary" @click.stop="clickPut" :disabled="items.length === 0">확인</button>
          </div>
        </div>
      </main>
    </div>
  </template>
<!--  웹 -->
  <template v-else>
    <div class="popup-container" :class="{ 'is-active' : show }">
      <div class="popup">
        <div class="popup-inner">
          <header class="popup-header">
            <h3 class="title">HRD 로드맵 담기</h3>
          </header>
          <div class="popup-content">
            <div class="popup-section">
              <div class="kb-form-fields">
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">로드맵명</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <div class="kb-form-text">[{{roadmap.hrdCloudCateNm}} {{roadmap.hrdCloudCateLevelNm}}] {{roadmap.roadmapTitle}}</div>
                  </div>
                </div>
                <div class="kb-form-row">
                  <div class="kb-form-column kb-form-column-title">
                    <label class="kb-form-label">
                      <span class="kb-form-label-text">콘텐츠명</span>
                    </label>
                  </div>
                  <div class="kb-form-column">
                    <template v-if="items.length > 0">
                      <div v-for="(item,idx) in items" :key="idx" class="kb-form-text">{{idx+1}}. {{item.objNm}}<br></div>
                    </template>
                    <div v-else class="kb-form-text text-muted">담기 가능한 콘텐츠가 없습니다.</div>
                  </div>
                </div>
              </div>
              <p class="text-muted pt-3">
                ※ HRD 클라우드 로드맵 – 그룹러닝 콘텐츠 담기는 동영상만 가능합니다.(전산실습, PDF 제외)
              </p>
            </div>
          </div>
          <div class="popup-buttons">
            <button class="kb-btn kb-btn-outline kb-btn-lg" @click="closePopup"><span class="text">취소</span></button>
            <button class="kb-btn kb-btn-primary kb-btn-lg" @click="clickPut" :disabled="items.length === 0"><span class="text">확인</span></button>
          </div>
        </div>
        <div class="popup-close">
          <button class="kb-btn-popup-close" @click="closePopup"><i class="icon-popup-close">팝업닫기</i></button>
        </div>
      </div>
    </div>
  </template>
</template>

<script>
import {computed, reactive, ref, watch} from "vue";
import {useStore} from "vuex";
import {useAlert} from "@/assets/js/modules/common/alert";
import HrdCloudMobileHeader from '@/components/hrdcloud/mobile/HrdCloudMobileHeader.vue';
import {cttTyCdDcds, showAdded} from "@/assets/js/modules/hrd/hrd-common";
import {hrdGroupPutGoToGroupPage, hrdPutGoToMyPageBtnTitle, hrdPutTitle} from "@/assets/js/modules/hrd/hrd-message";
import {actTyCdDcds} from "@/assets/js/modules/common/common";
import {initParams, setParams} from "@/assets/js/util";
import {ACT_GET_CRSE_ACT_ADD, ACT_INSERT_CRSE_ACT_LOG} from "@/store/modules/course/course";

export default {
  name: 'HrdCloudRaodmapGroupPutModal',
  components: {HrdCloudMobileHeader},
  props: {
    show: Boolean,
    roadmap: Object,
    cttList: Array,
    group: Object, // 그룹러닝 정보
    allPut: Boolean, // 그룹러닝 콘텐츠 일괄 담기 여부
  },
  emits: ['update:show', 'update:allPut'],
  setup(props, {emit}) {
    const store = useStore();
    const {showLoading, hideLoading, showConfirmCustomBtn} = useAlert();
    const isMobile = computed(() => store.state.auth.isMobile);
    const items = computed(() => props.cttList.filter(x => x.cttTyCdDcd !== cttTyCdDcds.CTT_TY_CD_PDF && x.cttTyCdDcd !== cttTyCdDcds.CTT_TY_CD_ZIP && x.lrnObjDtlDistSn > 0));
    const param = reactive({
      actTyCdDcd: '',
      distCrseSn : 0,
      distCrseNm : '',
      lrnObjDtlDistSn: 0,
      objNm: '',
      objSn: 0,
    });
    const allPut = ref(true);

    const closePopup = () => {
      emit('update:show', false);
    }

    const clickPut = async () => {
      showLoading();
      for (const item of items.value.toReversed()) {
        setParams(param, item);
        param.actTyCdDcd = actTyCdDcds.ACT_TY_CD_ADD;
        param.distCrseSn = props.group.distCrseSn;
        param.distCrseNm = props.group.distCrseNm;
        await store.dispatch(`course/${ACT_INSERT_CRSE_ACT_LOG}`, {...param}).finally(() => {initParams(param)});
      }
      hideLoading();
      showConfirmCustomBtn({
        title: hrdPutTitle,
        text: hrdGroupPutGoToGroupPage,
        okBtnTitle: hrdPutGoToMyPageBtnTitle,
        callback: () => {
          closePopup();
          showAdded(null, 'grouplearning');
        },
        cancelCallback: closePopup,
      });
      emit('update:allPut', true);
    }

    const checkGroupContentAllPut = async () => {
      for (const item of items.value){
        const addRes = await store.dispatch(`course/${ACT_GET_CRSE_ACT_ADD}`, {distCrseSn: props.group.distCrseSn, objSn: item.objSn});
        if(allPut.value) allPut.value = addRes.put;
      }
      if(allPut.value && items.value.length > 0){
        emit('update:allPut', allPut.value);
      }
    }

    watch(() => items.value, checkGroupContentAllPut)

    return {
      isMobile,
      items,
      closePopup,
      clickPut,
    }
  }
}
</script>