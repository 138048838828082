<template>
  <main class="kb-main" id="kb-hrdcloud">
    <lxp-main-header :show-back="true">
      <template v-slot:inner>
        <HrdCloudVisitor />
      </template>
    </lxp-main-header>
    <div v-if="!isLoading">
      <div class="roadmap-title">
        <div class="image" :color-no="roadmap.bannerUrl ? -1 : roadmap.hrdCloudRoadmapSn % 5">
          <img v-if="roadmap.bannerUrl" class="w-100 h-100" :src="roadmap.bannerUrl" alt="" loading="eager" />
        </div>
        <div class="title-area">
          <div class="d-flex align-items-center justify-content-center">
            <span class="text">{{roadmap.hrdCloudCateNm}}</span>
            <span v-if="roadmap.cttCmptnCount > 0 && roadmap.cttCmptnCount == cttList.length" class="content-badge">완료</span>
            <span v-else-if="roadmap.cttCmptnCount > 0 && roadmap.cttCmptnCount < cttList.length" class="content-badge progress-badge">진행중</span>
          </div>
          <span class="sub-text">{{roadmap.roadmapTitle}}</span>
        </div>
      </div>
      <div class="main-content main-component roadmap-content">
        <div class="roadmap-info-area" v-if="false">
          <div v-if="roadmap.roadmapKeyword.length > 0" class="mb-6">
            <span class="title">AI가 추출한 키워드</span>
            <div class="keyword-list">
              <div class="keyword" v-for="(item,idx) in roadmap.roadmapKeyword.split(',')" :key="idx" :cate-sn="roadmap.hrdCloudCateSn">{{item}}</div>
            </div>
          </div>
          <div v-if="roadmap.roadmapNote1.length > 0"  class="mb-6">
            <span class="title">이런 분들께 추천해요!</span>
            <div class="note" v-html="roadmap.roadmapNote1"></div>
          </div>
        </div>
        <div class="roadmap-data-area">
          <div>
            <div class="d-flex align-items-center">
              <h3 class="title">학습 목록<small class="text-muted ms-2 fw-bold">{{roadmap.cttCmptnCount}}/{{cttList.length}}</small></h3>
              <button v-if="groupLearning.distCrseSn > 0" class="grp-put-btn" data-content="그룹러닝 콘텐츠 일괄 담기" @click="showGroupLeaderPutModal = !showGroupLeaderPutModal">
                <i class="icon-interest" :class="{'is-active': groupLearningAllPut}" />
              </button>
            </div>
            <div v-if="cttList.length > 0" class="content-list">
              <HrdCloudRow :data-grid="4" :items="cttList" :session="session" :show-index="true" :show-put="true" />
            </div>
            <div v-else class="content-list"><span class="text-muted">학습 로드맵 콘텐츠가 없습니다.</span></div>
          </div>
        </div>
      </div>
      <HrdCloudRaodmapGroupPutModal v-if="groupLearning.distCrseSn > 0" v-model:show="showGroupLeaderPutModal" :roadmap="roadmap" :cttList="cttList" :group="groupLearning" v-model:allPut="groupLearningAllPut" />
    </div>
  </main>
</template>

<script>
import LxpMainHeader from '@/components/common/LxpMainHeader';
import HrdCloudVisitor from '@/components/hrdcloud/HrdCloudVisitor';
import HrdCloudRow from '@/components/hrdcloud/HrdCloudRow';
import HrdCloudRaodmapGroupPutModal from '@/components/hrdcloud/HrdCloudRoadmapGroupPutModal.vue';
import {hrdCloudRoadmapPageSetup} from "@/assets/js/modules/hrd/hrd-cloud-roadmap-setup";

export default {
  name: 'HrdCloudRoadmapPage',
  components: {LxpMainHeader, HrdCloudVisitor, HrdCloudRow, HrdCloudRaodmapGroupPutModal},
  setup: hrdCloudRoadmapPageSetup
}
</script>